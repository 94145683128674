import { useQuery, useMutation } from 'react-query';
import { PAYMENT_METHODS } from 'containers/CheckoutPage/constants';
import useGetVendor from 'utils/Hooks/useGetVendor';
import determineEnvironment, { determineIsWeChatBrowser } from 'utils/WeChat/determineEnvironment';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';
import { isMobileBrowser } from 'utils/helpers';
import { getTracks } from 'containers/OrderDetailPage/utils';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';

export const ORDER_QUERY_KEYS = {
  GET_ORDER: 'orderPage.order',
  GET_SHIPMENTS: 'orderPage.shipments',
  GET_PAYMENT_METHODS: 'orderPage.paymentMethods',
  GET_PAYMENT_METHODS_BY_ID: 'orderPage.getPaymentMethodsByQuoteId',
  GET_TRACKING_INFO: 'orderPage.trackingInfo',
  GET_PICK_UP_QR_CODE: 'orderPage.getPickupQrCode',
};

const filterPaymentMethods = (methods = [], isHui = false) => {
  let paymentMethods =
    methods?.filter(method => !['cashondelivery', 'banktransfer', 'free'].includes(method.code)) ||
    [];
  paymentMethods = isHui
    ? paymentMethods
    : paymentMethods.filter(method => !method.code.includes('huifu'));

  // miniProgram
  if (determineEnvironment()) {
    return paymentMethods.filter(
      method =>
        ![PAYMENT_METHODS.H_ALI, PAYMENT_METHODS.ALI, PAYMENT_METHODS.H_UNION].includes(
          method.code,
        ),
    );
  }

  // wechat browser
  if (determineIsWeChatBrowser()) {
    return paymentMethods.filter(
      method => ![PAYMENT_METHODS.H_ALI, PAYMENT_METHODS.ALI].includes(method.code),
    );
  }

  // mobile h5
  if (isMobileBrowser()) {
    return paymentMethods.filter(method => ![PAYMENT_METHODS.WECHAT].includes(method.code));
  }

  return paymentMethods;
};

export const getOrder = orderId => request('GET', `/customer/orders/${orderId}`);
export const cancelOrder = orderId => request('POST', `/order/${orderId}/cancel`);
export const getShipments = orderId => request('GET', `/customer/orders/${orderId}/shipments`);
export const getPaymentMethods = orderId => request('GET', `/order/${orderId}/payment-methods`);
export const getTrackingInfo = (carrierCode, trackNumber, phone) =>
  request('GET', `/kuaidi/track/${carrierCode}/${trackNumber}?phone=${phone}`);
export const reorder = payload => request('POST', '/customer/orders/reorder', payload);
export const behalfUseCredit = orderId =>
  request('POST', `/order-on-behalf/${orderId}/use-creddit`);
export const getPickupQrCode = (orderId, payload) =>
  request('GET', `/pick_up/${orderId}/qrcode`, payload);
export const updateBehalfOrder = data =>
  request('POST', '/order-on-behalf/update-order-status', data);
export const getPaymentMethodsByQuoteId = quoteId =>
  request('GET', `/quote/${quoteId}/payment-methods`);

export const useGetOrder = (id, options = {}) =>
  useQuery([ORDER_QUERY_KEYS.GET_ORDER, id], () => getOrder(id), {
    ...querySetting,
    enabled: !!id,
    ...options,
  });

export const useGetShipments = id =>
  useQuery(
    [ORDER_QUERY_KEYS.GET_SHIPMENTS, id],
    () => getShipments(id).then(response => getTracks(response)),
    { ...querySetting, enabled: !!id },
  );
export const useGetPaymentMethods = id => {
  const vender = useGetVendor();
  const { data, ...other } = useQuery(
    id && [ORDER_QUERY_KEYS.GET_PAYMENT_METHODS, id],
    () => getPaymentMethods(id),
    querySetting,
  );

  const paymentMethods = filterPaymentMethods(data, vender?.extension_attributes?.huifu_status);

  return {
    data: paymentMethods,
    ...other,
  };
};
export const useGetPaymentMethodsByQuoiteId = id => {
  const vender = useGetVendor();
  const { data, ...other } = useQuery(
    [ORDER_QUERY_KEYS.GET_PAYMENT_METHODS_BY_ID, id],
    () => getPaymentMethodsByQuoteId(id),
    {
      ...querySetting,
      enabled: !!id,
    },
  );

  const paymentMethods = filterPaymentMethods(data, vender?.extension_attributes?.huifu_status);

  return {
    data: paymentMethods,
    ...other,
  };
};

export const useGetTrackingInfo = ({ carrierCode, trackNumber, phone }) => {
  const notify = useNotificationSnackbar();

  return useQuery(
    carrierCode &&
      trackNumber &&
      phone && [ORDER_QUERY_KEYS.GET_TRACKING_INFO, carrierCode, trackNumber, phone],
    () =>
      new Promise((resolve, reject) => {
        getTrackingInfo(carrierCode, trackNumber, phone)
          .then(res => {
            const result = JSON.parse(res);
            if (!result.result) {
              notify(result.message, 'warn');
              reject(result.message);
              return;
            }
            resolve(result);
          })
          .catch(err => {
            notify(err.message, 'warn');
            reject(err.message);
          });
      }),
    querySetting,
  );
};

export const useGetTrackingInfoMutation = () =>
  useMutation(({ logisticCode, trackingNumber, phone }) =>
    getTrackingInfo(logisticCode, trackingNumber, phone).then(res => JSON.parse(res)),
  );
export const useReorder = () => useMutation(payload => reorder(payload));
export const useCancelOrder = () => useMutation(id => cancelOrder(id));
export const useGetPickupQrCodeQuery = orderId =>
  useQuery([ORDER_QUERY_KEYS.GET_PICK_UP_QR_CODE, orderId], () => getPickupQrCode(orderId), {
    ...querySetting,
    enabled: !!orderId,
  });
export const useBehalfUseCredit = () => useMutation(orderId => behalfUseCredit(orderId));
export const useUpdateBehalfOrder = () => useMutation(payload => updateBehalfOrder(payload));
